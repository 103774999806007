<template>

	<div class="section-account-info container py-6">
		<p class="text-center">
			<router-link to="/account" class="btn btn-outline-dark">← Account</router-link>
		</p>

		<div v-if="account" class="row justify-content-center">
			<div class="col-md-11 col-lg-10">
				<div class="card my-3">
					<div class="card-body">
						<div class="row">
							<div class="col">
								<h3 class="mb-4">API Keys</h3>
							</div>
							<div class="col-auto">
								<!-- <button class="btn btn-sm btn-outline-dark" disabled>New API Key</button> -->
							</div>
						</div>

						<p class="mb-2">Your keys for Domains API are listed below.</p>
						<p>Do not share your API key with others, or expose it in the browser/client-side code.</p>

						<div class="card-table mb-5">
							<table class="table table-hover">
								<thead>
									<tr>
										<th>Name</th>
										<th>Key</th>
										<th>Created</th>
										<th>Last used</th>
										<th>Hits this month</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="apiKey in apiKeys" :key="apiKey.key">
										<td>{{ apiKey.name }}</td>
										<td>
											<code>{{ apiKey.key }}</code>
										</td>
										<td>{{ apiKey.created_at | dateLocal }}</td>
										<td>
											<small v-if="apiKey.last_used_at" :title="apiKey.last_used_at">{{ formatDistanceToNowStrict(new Date(apiKey.last_used_at), { addSuffix: true }) }}</small>
											<small v-else class="text-muted">-</small>
										</td>
										<td>
											<a :href="`https://domains-api.com/api-keys/${apiKey.key}/requests`" target="_blank" class="text-dark">{{ apiKey.hits_month.toLocaleString() }}</a>
										</td>
									</tr>
									<tr v-if="!apiKeys.length">
										<td colspan="5">
											<p class="my-4 text-center text-muted">No API Keys yet. <button class="btn btm-sm btn-outline-primary ms-2" @click="createApiKey">➕ Create one now</button></p>
										</td>
									</tr>
								</tbody>
							</table>
						</div>

						<h5 class="mb-2">Quick API example</h5>
						<p class="mb-2">The best way to integrate the API in your project is to use your preferred HTTP client/library and use the JSON response.</p>

						<pre class="border rounded p-2 mb-4"><span class="text-muted"># Auth with Bearer token</span>
$ curl -H "Authorization: Bearer {{ firstApiKey }}" https://domains-api.com/domains/example.com/whois

<span class="text-muted"># Auth With query parameter</span>
$ curl https://domains-api.com/domains/example.com/whois?apiKey={{ firstApiKey }}</pre>

						<h5 class="mb-2">API documentation</h5>
						<p class="card-text">Check out the <a href="https://docs.dmns.app/api">API docs</a> to learn more about all endpoints and see comprehensive examples.</p>
					</div>
				</div>
			</div>
		</div>
		<div v-else>
			<div class="spinner-border" role="status"></div>
		</div>
	</div>

</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import { formatDistanceToNowStrict } from 'date-fns'

import domainsApi from "@/api.js"

export default {
	name: 'AccountApiKeys',
	data() {
		return {
			apiKeys: [],
		}
	},
	computed: {
		...mapState([ 'account', 'apiUrl', 'plan' ]),
		firstApiKey() {
			return this.apiKeys[0]?.key || '[YOUR_API_KEY]'
		},
	},
	created() {
		this.$store.dispatch('ev', {
			event: 'View API Keys',
			data: { notify: true }
		})

		this.loadApiKeys()
	},
	methods: {
		formatDistanceToNowStrict,

		loadApiKeys() {
			domainsApi('account/api-keys').then(({ data }) => {
				this.apiKeys.push(...data)
			}, error => {
				Vue.toasted.error(`Error loading API Keys (${error.message})`)
			})
		},
		createApiKey() {
			const name = prompt('Enter a name for the new API Key', 'First API Key')

			if (name && name.trim().length) {
				domainsApi.post('account/api-keys', { name }).then(({ data }) => {
					this.apiKeys.push(data)
					Vue.toasted.success(`API Key "${name}" created successfully`)
				}, error => {
					Vue.toasted.error(`Error loading API Keys (${error.message})`)
				})
			}
		}
	},
}
</script>
